/*

We can conditionally show/hide some fields based on the presence of value in one field

HOW TO USE:

The top level class should have the data-controller="conditional-show-hide" attribute.

The input fields with: 
  conditional_show_hide_target: "isConditional", show_on_condition: "true"
will be visible only when the input field with 
  conditional_show_hide_target: "conditionalInput" 
has some value in it.

<div data-controller="conditional-show-hide"
 <%= f.text_area :body, data: { action: "input->conditional-show-hide#checkContent", conditional_show_hide_target: "conditionalInput" } %>
  <%= link_to "Link1", data: { conditional_show_hide_target: "isConditional", show_on_condition: "false" } %>
  <%= link_to "Link2", data: { conditional_show_hide_target: "isConditional", show_on_condition: "true"  } %>
  <%= link_to "Link3", data: { conditional_show_hide_target: "isConditional", show_on_condition: "true"  } %>
</div>

*/

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["conditionalInput", "isConditional"]

  connect() {
    this.updateVisibility();
  }

  updateVisibility() {
    const hasContent = this.conditionalInputTarget.value.trim().length > 0;
    this.isConditionalTargets.forEach(target => {
      const shouldShow = target.dataset.showOnCondition === "true";
      if (hasContent) {
        if (shouldShow) {
          target.classList.remove("hidden");
        } else {
          target.classList.add("hidden");
        }
      } else {
        if (shouldShow) {
          target.classList.add("hidden");
        } else {
          target.classList.remove("hidden");
        }
      }
    });
  }

  checkContent() {
    this.updateVisibility();
  }
}
